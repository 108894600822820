<template>
  <div class="pa-5">
    <base-material-card
      color="success"
      icon="mdi-clipboard-text"
      inline
      title="文章管理"
      class="px-5 py-3 mb-5"
    >
      <v-row class="my-3">
        <v-col md="1">
          <v-btn
            depressed
            color="success"
            to="/admin/article-edit"
          >
            <v-icon left>
              fa fa-plus
            </v-icon>
            添加
          </v-btn>
        </v-col>
        <v-col md="2">
          <v-select
            v-model="column"
            label="所属栏目"
            :items="columnList"
            item-text="name"
            item-value="id"
            clearable
            outlined
            dense
          />
        </v-col>
        <v-col md="1">
          <v-btn
            depressed
            color="success"
            @click="getArticle"
          >
            <v-icon left>
              fa fa-search
            </v-icon>
            搜索
          </v-btn>
        </v-col>
      </v-row>
      <v-simple-table>
        <thead>
          <tr>
            <th>ID</th>
            <th>文章标题</th>
            <th>所属栏目</th>
            <th class="text-right">
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(article, index) in articles"
            :key="index"
          >
            <td>{{ article.id }}</td>
            <td>{{ article.title }}</td>
            <td>{{ article.categoryId }}</td>
            <td class="text-right">
              <v-btn
                class="mx-2"
                small
                color="success"
                @click="editArticle(index)"
              >
                <v-icon
                  small
                  left
                >
                  mdi-pencil
                </v-icon>
                编辑
              </v-btn>
              <v-btn
                class="mx-2"
                small
                color="error"
                @click="delArticle(index)"
              >
                <v-icon
                  small
                  left
                >
                  fa fa-trash-o
                </v-icon>
                删除
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="6"
        />
      </div>
    </base-material-card>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        column: null,
        columnList: [],
        page: 1,
        actions: [
          {
            color: 'success',
            icon: 'mdi-pencil',
          },
          {
            color: 'error',
            icon: 'mdi-close',
          },
        ],
        dialog: false,
        articles: [],
      }
    },
    created () {
      this.getColumn()
      this.getArticle()
    },
    methods: {
      // 获取栏目
      getColumn () {
        this.$axios.get('/categories/article')
          .then(res => {
            this.columnList = res.data.data
          })
          .catch(err => {
            console.log(err)
          })
      },
      getArticle () {
        let resData = null
        if (!this.column) {
          resData = this.$axios.get('/posts')
        } else {
          resData = this.$axios.get('/posts', {
            params: {
              categoryId: this.column,
            },
          })
        }
        resData.then(res => {
          this.articles = res.data.data.content
        })
          .catch(err => {
            console.log(err)
          })
      },
      delArticle (index) {
        this.$axios.delete(`/posts/${this.articles[index].id}`)
          .then(res => {
            this.articles.splice(index, 1)
            this.$swal({
              title: '删除成功',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(err => {
            this.$swal({
              title: '删除失败',
              icon: 'error',
              text: '原因:' + err,
              confirmButtonText: '知道了',
            })
          })
      },
      editArticle (index) {
        this.$router.push({
          path: '/admin/article-edit',
          query: {
            id: this.articles[index].id,
          },
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .main{
    margin-top: 0;
  }
</style>
